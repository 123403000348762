import React from "react";
import SEO from "components/seo";
import useHerosTypesData from "shared/Hooks/PageHeaders/useHerosTypesData";
import { ArticlesList } from "sections/Articles";
import { CenteredTextBox } from "components/TextBoxes";
import { BigHero } from "components/BigHero";
import { BLOG } from "shared/Types/heros";
import { useArticlesData } from "shared/Hooks/Articles/useArticlesData";

const Blog = () => {
  const data = useHerosTypesData(BLOG);
  const posts = useArticlesData();
  const posts_array = Object.values(posts);

  return (
    <>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <CenteredTextBox
        titleTag="h2"
        title="Marketing jest jak Układ Słoneczny - tu się z zawsze dużo dzieje. Bądź na bieżąco z najnowszymi trendami i nowościami."
      />
      <ArticlesList data={posts_array} />
    </>
  );
};

export default Blog;
