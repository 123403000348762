import styled from "styled-components";
import { Link } from "gatsby";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";

export const Container = styled(GlobalWrapper)`
  overflow: hidden;
  margin-bottom: 72px;
`;

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-wrap: wrap;
  max-width: 1048px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Item = styled(Link)`
  width: calc(50% - 24px);
  display: inline-block;
  margin-bottom: 48px;
  &:not(:nth-child(2n)) {
    margin-right: 48px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: calc(50% - 18px);
    margin-bottom: 36px;
    &:not(:nth-child(2n)) {
      margin-right: 36px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: calc(50% - 12px);
    margin-bottom: 24px;
    &:not(:nth-child(2n)) {
      margin-right: 24px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: 100%;
    margin-right: 0 !important;
  }
`;

export const ImageBox = styled.div`
  width: 100%;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.blog_background};
    z-index: -1;
  }
  .gatsby-image-wrapper {
    width: 200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Date = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1rem;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.8rem;
  font-weight: 800;
  margin: 4px 0 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.6rem;
  }
`;
