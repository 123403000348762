import React from "react";
import {
  Container,
  Wrapper,
  Item,
  ImageBox,
  TextBox,
  Date,
  Title,
} from "./styles";

export const ArticlesList = ({ data }) => {
  return (
    <Container>
      <Wrapper>
        {data.reverse().map((item, i) => (
          <Item to={`${item.slug}`} title="" key={i}>
            <ImageBox> {item.hero}</ImageBox>
            <TextBox>
              <Date>{item.date}</Date>
              <Title>{item.title}</Title>
            </TextBox>
          </Item>
        ))}
      </Wrapper>
    </Container>
  );
};
